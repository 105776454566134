let ShareView = {
    computed: {
        isShareAvailable() {
            return !!(navigator && navigator.share);
        }
    },

    methods: {
        share() {
            if (navigator.share) {
                navigator.share({
                    title: 'Leen Platform',
                    text: 'Leen Platform',
                    url: window.location.href
                })
                    .then(() => console.log('Успешно поделились'))
                    .catch((error) => console.log('Ошибка при попытке поделиться', error));
            } else {
                alert('Ваше устройство не поддерживает функцию поделиться');
            }
        },
    }
};


export default ShareView;
