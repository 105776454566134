<script>
import Vue, {h} from 'vue'

let extend = Vue.extend.bind(Vue)

const toType = value => typeof value
const isFunction = value => toType(value) === 'function'
const identity = x => x;
const concat = (...args) => Array.prototype.concat.apply([], args)
const normalizeSlot = (names, scope = {}, $scopedSlots = {}, $slots = {}) => {
    // Ensure names is an array
    names = concat(names).filter(identity)
    let slot
    for (let i = 0; i < names.length && !slot; i++) {
        const name = names[i]
        slot = $scopedSlots[name] || $slots[name]
    }
    // Note: in Vue 2.6.x, all named slots are also scoped slots
    return isFunction(slot) ? slot(scope) : slot
}

export default extend({
    props: {
        title: {
            type: String,
            required: false,
            default: ''
        },
        tabContentClass: {
            type: String|Object,
            required: false,
            default: ''
        }
    },

    inject: {
        getBvTabs: {
            default: () => () => ({})
        }
    },

    computed: {
        bvTabs() {
            return this.getBvTabs()
        },
    },

    mounted() {
        this.registerTab()
    },

    beforeDestroy() {
        this.unregisterTab()
    },

    methods: {
        registerTab() {
            const {registerTab} = this.bvTabs

            if (registerTab) {
                registerTab(this)
            }
        },
        unregisterTab() {
            const {unregisterTab} = this.bvTabs
            if (unregisterTab) {
                unregisterTab(this)
            }
        },
        normalizeSlot(
            name = 'default',
            scope = {},
            scopedSlots = this.$scopedSlots,
            slots = this.$slots
        ) {
            const vNodes = normalizeSlot(name, scope, scopedSlots, slots)
            return vNodes ? concat(vNodes) : vNodes
        }
    },

    render(createElement, context) {
        return null;
    }
});
</script>
