<script>
import UserSelectingPopup from "@/components/Form/UserSelectionPopup.vue";
import FileDialog from "@/components/FileDialog.vue";

export default {
    name: "CommentInput",

    components: {
        FileDialog,
        UserSelectingPopup
    },

    model: {
        prop: 'value',
        event: 'input'
    },

    props: {
        value: {
            type: String,
            default: '',
        }
    },

    data() {
        return {
            loading: false,
            showUserSelecting: false,
            innerValue: '',
            inputAfterAt: "",
            activeIndex: null,
            users: null,


            files: [],
            dialog_file: false,
            document_urls: [],
        }
    },

    watch: {
        value: {
            immediate: true,
            handler(value) {
                this.innerValue = value;
            }
        },

        innerValue(value) {
            this.$emit('input', value);
        }
    },

    methods: {
        handleEnterKey(event) {
            if (event.key === 'Enter' && !event.shiftKey && !event.ctrlKey && !event.altKey && !event.metaKey) {

                if(this.showUserSelecting){
                    this.selectSuggestion(this.users[this.activeIndex]);
                    return;
                }

                this.sentMessage();
            } else {
                event.preventDefault();
                if (!event.shiftKey) {
                    this.innerValue += '\n';
                }
            }
        },

        async handleInput() {
            let text = this.innerValue

            const lastChar = text.slice(-1);

            if (lastChar === "@") {
                this.showUserSelecting = true;
                const textareaRect = this.$refs.textarea.$el.getBoundingClientRect();
                this.menuX = textareaRect.left;
                // this.menuY = textareaRect.bottom - 220;
                this.activeIndex = 0;
            } else if (lastChar === " ") {
                // Если введен пробел, закрываем меню
                this.showUserSelecting = false;
                // Сбрасываем текст после "@" при введении пробела
                this.inputAfterAt = "";
                this.activeIndex = 0;
            } else if (this.showUserSelecting) {
                // Если меню открыто и введенный символ не пробел, обновляем текст после "@"
                const atIndex = text.lastIndexOf("@");
                this.inputAfterAt = text.substring(atIndex + 1);
            }

            this.$emit('input', text);
        },

        handleArrowDown() {
            if(this.users){
                if(!this.activeIndex === null) {
                    this.activeIndex = 0;
                } else if (this.activeIndex < this.users.length - 1) {
                    this.activeIndex += 1;
                }
            } else {
                this.activeIndex = null;
            }
        },

        handleArrowUp() {
            if (this.activeIndex > 0) {
                this.activeIndex -= 1;
            } else {
                this.activeIndex = null;
            }
        },

        sentMessage() {
            this.$emit('input', this.innerValue);
            this.$emit('send', this.innerValue);
        },

        sentMessageFile(files) {
            this.$emit('sendFiles', files);
        },

        selectSuggestion(user){
            if(!user) {
                return;
            }

            let userRow = (`${user.login}`);

            // Заменяем текст в textarea выбранным предложением
            if (this.inputAfterAt.length > 0) {
                this.innerValue = this.innerValue.slice(0, -this.inputAfterAt.length) + userRow + " ";
            } else {
                this.innerValue = this.innerValue + userRow + " "
            }
            this.showUserSelecting = false;

            // Сбрасываем текст после "@" после выбора предложения
            this.inputAfterAt = "";
            this.activeIndex = 0;
            this.$nextTick(() => {
                this.$refs.textarea.focus();
            });
        },

        onClickOutside() {
            if(this.showUserSelecting){
                this.showUserSelecting = false;
                this.activeIndex = 0;
            }
        },

        openFileDialog() {
            const messageFileNode = this.$refs['message-file'];
            messageFileNode.$el.querySelector('input[type=file]').click();
        },
    }
}
</script>

<template>
<div v-click-outside="onClickOutside">
    <v-textarea
        v-model="innerValue"
        :label="$t('message')"
        :disabled="loading"
        no-details
        rows="1"
        auto-grow
        full-width
        id="chat-message-typer-textarea"
        hide-details
        class="input_textarea send_msg"
        ref="textarea"

        @keyup.enter="handleEnterKey"
        @input="handleInput"
        @keydown.down.prevent="handleArrowDown"
        @keydown.up.prevent="handleArrowUp"
    >
        <template v-slot:append>
            <v-icon @click="openFileDialog">mdi-paperclip</v-icon>
        </template>
        <template v-slot:append-outer>
            <v-icon @click="sentMessage" class="mr-6">mdi-send</v-icon>
        </template>
    </v-textarea>
    <UserSelectingPopup
        v-if="showUserSelecting"
        @input="selectSuggestion"
        @users-fetch="users = $event"
        :search-value="inputAfterAt"
        :focus-item-index="activeIndex"
    />

    <v-file-input
        ref="message-file"
        style="display: none"
        prepend-icon=""
        v-model="files"
        multiple
        @change="dialog_file = true"
    >
    </v-file-input>
    <FileDialog
        v-model="files"
        :showDialog="dialog_file"
        @closeDialog="dialog_file = false"
        @send="sentMessageFile"
    />
</div>
</template>

<style scoped lang="scss">

</style>
