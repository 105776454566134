<script>
export default {
    name: "FileDialog",

    model: {
        prop: 'files',
        event: 'input'
    },

    props: {
        files: {
            type: Array,
            default() {
                return [];
            },
        },

        showDialog: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            innerFiles: []
        }
    },

    watch: {
        files: {
            immediate: true,
            handler(value) {
                this.innerFiles = value;
            }
        },

        innerFiles(value) {
            this.$emit('input', value);
        }
    },

    computed: {
        previewFiles: {
            get() {
                let files = [];

                this.files.forEach((file) => {
                    let data = {}
                    data.url = URL.createObjectURL(file)
                    data.name = file.name
                    data.blob =  file
                    data.size = file.size
                    data.type = file.type
                    files.push(data)
                })

                return files;
            }
        },
    },

    methods: {
        closeDialog() {
            this.$emit('closeDialog');
        },

        deleteFile(item) {
            let index = this.files.indexOf(item)
            this.innerFiles.splice(index, 1)
            if (this.files.length === 0) {
                this.closeDialog()
            }
            this.$emit('input', this.innerFiles);
        },

        sendMessageFile() {
            this.$emit('send', this.previewFiles);
            this.closeDialog();
        },


    }
}
</script>

<template>
    <v-dialog
        v-model="showDialog"
        scrollable
        persistent
        max-width="700px"
        transition="dialog-bottom-transition"
    >
        <v-card>
            <v-toolbar class="dialog_toolbar" elevation="0">
                <div class="ma-auto ml-0">
                    <span>{{ $t('send_files', {'count': previewFiles.length}) }}</span>
                </div>
                <div>
                    <v-icon @click="closeDialog" :title="$t('close')">mdi-close</v-icon>
                </div>
            </v-toolbar>
            <v-card-text style="height: 300px;">
                <v-list subheader>
                    <v-list-item v-for="document in previewFiles" :key="document.id">
                        <v-list-item-icon>
                            <v-img
                                max-width="90"
                                :alt="`${document.name}`"
                                :src="document.url"
                                max-height="60"
                            ></v-img>
                            <v-icon
                                x-large
                                v-if="!mimeTypeImage(document.type)">mdi-file-document-outline mdi-48px
                            </v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title v-text="document.name"></v-list-item-title>
                            <v-list-item-subtitle v-text="(document.size / 1000).toFixed(1) + ' KB'"></v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-icon class="d-flex align-self-center mt-2">
                            <v-btn
                                icon
                                @click="deleteFile(document)"
                            >
                                <v-icon>mdi-delete-outline</v-icon>
                            </v-btn>
                        </v-list-item-icon>
                    </v-list-item>
                </v-list>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="primary"
                    @click="sendMessageFile"
                >
                    {{ $t('sent') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
